let prevScrollTop = window.pageYOffset;
window.onscroll = () => {
  const currentScrollTop = Math.max(window.pageYOffset, 0);

  if (currentScrollTop >= 100 && prevScrollTop < 100) {
    document.body.classList.add("sticky-shrinknav-wrapper");
  } else if (currentScrollTop < 30) {
    document.body.classList.remove("sticky-shrinknav-wrapper");
  }
  prevScrollTop = currentScrollTop;
};
